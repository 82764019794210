<h1 mat-dialog-title>Aktualna pogoda</h1>
<div mat-dialog-content>
  <p>Miasto: {{data.name}}</p>
  <p>Opis: {{ data.weather["0"].description }}</p>
  <p>Temperatura: {{ data.main.temp }}</p>
  <p>Temp odczuwalna: {{ data.main.feels_like }}</p>
  <p>Ciśnienie: {{ data.main.pressure }}</p>
  <p>Wiatr: {{data.wind.speed}}</p>
  <p>Kierunek wiatru: {{data.wind.deg}}</p>
  <p>Data wyszukiwania {{data.date | date: 'dd-MM-yyyy H:mm'}}</p>
</div>
